import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "advertisement-fees", style: _vm.gridStyle },
    [
      _c("div", { staticClass: "advertisement-fees__header" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t(`${_vm.localizationKey}.rateCard`)
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "advertisement-fees__header" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t(`${_vm.localizationKey}.price`, {
                  currencySymbol: _vm.currencySymbol,
                })
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.model, function (rateCard, index) {
        return _c(
          "div",
          {
            key: `rateCard::${rateCard._id}`,
            staticClass: "advertisement-fees__item",
          },
          [
            _c(
              "div",
              { staticClass: "advertisement-fees__rate-card" },
              [
                _c(
                  VTooltip,
                  {
                    attrs: {
                      "z-index": "400",
                      top: "",
                      "max-width": 500,
                      disabled: !_vm.isRateCardDisabled(index),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "vuex-select",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "item-text": "description",
                                          "item-value": "_id",
                                          options: _vm.getOptions(index),
                                          getter: () =>
                                            _vm.rateCardGetter(index),
                                          setter: (value) =>
                                            _vm.rateCardSetter(index, value),
                                          filled: "",
                                          validations: _vm.validationRules,
                                          disabled:
                                            _vm.isRateCardDisabled(index),
                                        },
                                      },
                                      "vuex-select",
                                      attrs,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.model[index].description) + " "),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertisement-fees__amount-area" },
              [
                _vm.isRevertVisible(rateCard)
                  ? _c("icon-button", {
                      staticClass: "advertisement-fees__revert-btn",
                      attrs: {
                        icon: "new-revert",
                        disabled:
                          !_vm.isRateCardSelected(rateCard) &&
                          _vm.isRateCardAssigned(rateCard),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.revertAmount(index, rateCard._id)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("vuex-number-input", {
                  staticClass: "advertisement-fees__amount",
                  attrs: {
                    getter: () => rateCard.amount,
                    setter: (value) => _vm.amountSetter(index, value),
                    filled: "",
                    validations: _vm.valueValidationRules,
                    disabled:
                      !_vm.isRateCardSelected(rateCard) &&
                      _vm.isRateCardAssigned(rateCard),
                    format: "numbers.default.currencyNoLabel",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "advertisement-fees__expanded-wrapper" }, [
              _vm.isRateCardAssigned(rateCard) &&
              !_vm.isRateCardSelected(rateCard)
                ? _c(
                    "div",
                    { staticClass: "advertisement-fees__action-btns" },
                    [
                      _c("icon-button", {
                        attrs: { icon: "edit" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.setSelectedRateCardIdForSplitting(
                              rateCard._id
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.doesRateCardAmountMatchTotal(rateCard)
                        ? _c("icon", {
                            attrs: { "icon-name": "success-circle" },
                          })
                        : _c("icon", {
                            attrs: { "icon-name": "failure-circle" },
                          }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "advertisement-fees__apply-btn" },
                    [
                      _c(
                        "simple-button",
                        {
                          attrs: {
                            disabled:
                              _vm.isRateCardSelected(rateCard) || !rateCard._id,
                          },
                          on: {
                            onClick: function ($event) {
                              return _vm.setSelectedRateCardIdForSplitting(
                                rateCard._id
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t("actions.Apply")
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "advertisement-fees__delete-btn" },
                [
                  _c("common-delete-dialog", {
                    attrs: {
                      resource: _vm.promotionRateCardResource,
                      "child-dependencies-warning": false,
                    },
                    on: {
                      delete: function ($event) {
                        return _vm.deleteRateCard(index)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "advertisement-fees__new-item" },
        [
          _c("create-new-button", {
            attrs: { disabled: _vm.addButtonDisabled },
            on: { createNew: _vm.addRateCard },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "advertisement-fees__expanded-wrapper" }, [
      _c("div", { staticClass: "advertisement-fees__header" }),
      _vm._v(" "),
      _c("div", { staticClass: "advertisement-fees__header" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }