'use strict';

const { find, isEmpty } = require('lodash');
const requirementTypes = require('./data/enums/requirement-types');
const rewardTypes = require('./data/enums/reward-types');
const { isCategoryOrStoreWidePromotion } = require('./promotion-utils');

const baseTranslationKey = 'planning.promotionsMaintenance.promotionMechanic.offerDescriptions';
const offerTranslationKey = `${baseTranslationKey}.newOffers`;

const toSentenceCase = phrase => {
    if (!phrase) return phrase;

    const firstLetter = phrase.charAt(0);
    const remainingLetters = phrase.slice(1);

    return `${firstLetter.toUpperCase()}${remainingLetters}`;
};

const offerMechanicDescriptionGeneratorFactory = function(i18n) {
    /**
     * Checks whether the reward type is monetary.
     *
     * @param {object} params
     * @param {string} params.rewardType - The reward type to check.
     */
    const isMonetaryRewardType = ({ rewardType }) => {
        return (
            rewardType === rewardTypes.saveMoney ||
            rewardType === rewardTypes.saveMoneyPerItem ||
            rewardType === rewardTypes.setMoney
        );
    };

    /**
     * Generates a message describing all of the rewards in a tier.
     * @param {Object} params
     * @param {Object} params.tierRewards - The rewards included in the tier.
     */
    const generateRewardsMessage = ({ tierRewards = [] }) => {
        // Each tier can have multiple rewards. Loop through each one to build up the whole reward
        // for the tier.
        const rewards = tierRewards.map(reward => {
            // The reward can either be an amount or currency. Ensure the reward value is converted to a
            // currency value if required.
            const rewardValue = isMonetaryRewardType({ rewardType: reward.type })
                ? i18n.n('numbers.default.currency', reward.amount)
                : reward.amount;

            // Each reward type has its own translation describing how to setup the reward text
            // with its value.
            return i18n.t(`${baseTranslationKey}.rewardTypes.${reward.type}`, {
                value: rewardValue,
                loyaltyPoint: reward.description,
            });
        });

        return rewards.join(i18n.t(`${baseTranslationKey}.rewardsSeparator`));
    };

    /**
     * Generates a message describing all of the requirements in a tier.
     * @param {Object} params
     * @param {Object} params.tierRequirements - The requirements included in the tier.
     */
    const generateRequirementsMessage = ({ tierRequirements = [] }) => {
        const requirements = tierRequirements.map(requirement => {
            // If the requirement type is to spend money, then the requirement amount needs to include
            // the currency symbol.
            const requirementValue =
                requirement.type === requirementTypes.spendMoney
                    ? i18n.n('numbers.default.currency', requirement.amount)
                    : requirement.amount;

            return i18n.t(`${baseTranslationKey}.requirementTypes.${requirement.type}`, {
                value: requirementValue,
                loyaltyPoint: requirement.description,
            });
        });

        return requirements.join(i18n.t(`${baseTranslationKey}.requirementsSeparator`));
    };

    /**
     * Generates a description based on the params provided.
     *
     * @param {object} params
     * @param {string} params.requirementsMessage - Generated requirements description.
     * @param {string} params.rewardsMessage - Generated rewards description.
     * @param {string} params.offerGroupMessage - The offer group message to display at the end of the description.
     * @param {boolean} params.switchedMessage - If false, requirements message displays first. If true, rewards message displays first.
     */
    const generateMessage = ({
        requirementsMessage,
        rewardsMessage,
        offerGroupMessage,
        switchMessage = false,
    }) => {
        if (!requirementsMessage && !rewardsMessage) return '';

        const description = switchMessage ? 'switchedDescription' : 'description';
        const separator = switchMessage ? 'switchedMechanicSeparator' : 'mechanicSeparator';

        return i18n.t(`${offerTranslationKey}.${description}`, {
            requirements: toSentenceCase(requirementsMessage),
            mechanicSeparator:
                requirementsMessage && rewardsMessage
                    ? i18n.t(`${offerTranslationKey}.${separator}`)
                    : '',
            rewards: toSentenceCase(rewardsMessage),
            offerGroup: offerGroupMessage,
        });
    };

    /**
     * Generates a description for each product ofer group in the promotion.
     *
     * @param {object} params
     * @param {array} params.rewards - The POG reward attributes.
     * @param {array} params.requirements - The POG requirement attributes.
     * @param {array} params.offerGroup - The offer group to generate the description for.
     * @param {boolean} params.isDescriptionWithoutProducts - Feature flag to display offer group description or not.
     */
    const generatePOGOfferDescription = ({
        rewards,
        requirements,
        offerGroup = {},
        isDescriptionWithoutProducts,
    }) => {
        const requirementsMessage = generateRequirementsMessage({
            tierRequirements: requirements,
        });
        const rewardsMessage = generateRewardsMessage({ tierRewards: rewards });

        const offerGroupMessage = !isDescriptionWithoutProducts
            ? i18n.t(`${offerTranslationKey}.onOfferGroup`, {
                  group: offerGroup.description,
              })
            : i18n.t(`${offerTranslationKey}.onHiddenOfferGroup`, {
                  groupNumber: offerGroup._id,
              });

        const switchMessage = !!find(requirements, req => req.type === requirementTypes.onNthItem);

        return generateMessage({
            requirementsMessage,
            rewardsMessage,
            offerGroupMessage,
            switchMessage,
        });
    };

    /**
     * Generates the Global offer description.
     *
     * @param {object} params
     * @param {array} params.rewards - The offer-mechanic reward attributes.
     * @param {array} params.requirements - The offer-mechanic requirement attributes.
     */
    const generateGlobalOfferDescription = ({ rewards, requirements }) => {
        const requirementsMessage = generateRequirementsMessage({
            tierRequirements: requirements,
        });
        const rewardsMessage = generateRewardsMessage({
            tierRewards: rewards,
        });

        const switchMessage = !!find(requirements, req => req.type === requirementTypes.onNthItem);

        return generateMessage({
            requirementsMessage,
            rewardsMessage,
            offerGroupMessage: i18n.t(`${offerTranslationKey}.onGlobal`),
            switchMessage,
        });
    };

    /**
     * Generates a human-readable description of the offer-mechanic.
     *
     * @param {object} params
     * @param {object} params.offerMechanic - The offer mechanic to generate the description from.
     * @param {array} params.offerGroups - The offer groups included in the promotion.
     */
    const generateOfferDescription = ({ offerMechanic, offerGroups = [] }) => {
        // Loop through all tiers and build up the requirement amount and applicable rewards for each tier.
        const tiers = offerMechanic.tiers.map(tier => {
            const offerContainsGlobalMechanic =
                (tier.globalRequirements && !isEmpty(tier.globalRequirements)) ||
                (tier.globalRewards && !isEmpty(tier.globalRewards));

            const globalDescription = offerContainsGlobalMechanic
                ? generateGlobalOfferDescription({
                      requirements: tier.globalRequirements,
                      rewards: tier.globalRewards,
                  })
                : '';

            const offerContainsPOG = tier.productOfferGroups && !isEmpty(tier.productOfferGroups);

            const pogsDescription = offerContainsPOG
                ? tier.productOfferGroups.map(pogMechanics => {
                      if (isEmpty(pogMechanics.requirements) && isEmpty(pogMechanics.rewards)) {
                          return '';
                      }
                      return generatePOGOfferDescription({
                          requirements: pogMechanics.requirements,
                          rewards: pogMechanics.rewards,
                          offerGroup: find(offerGroups, pog => pog._id === pogMechanics._id),
                      });
                  })
                : [''];

            return (
                globalDescription +
                pogsDescription.join(i18n.t(`${offerTranslationKey}.offerGroupsSeparator`))
            );
        });

        if (isEmpty(tiers) || isEmpty(tiers[0])) {
            return i18n.t(`${baseTranslationKey}.noMechanics`);
        }

        // All tiers have now been described for the offer.
        // Now describe the whole offer by combining all tiers together.
        return tiers.join(i18n.t(`${offerTranslationKey}.tiersSeparator`));
    };

    /**
     * Main entry point to the generator, responsible for generating a human-readable description of the offer
     * based on the attributes set on the offer.
     *
     * @param {object} params
     * @param {object} params.offerMechanic - The offer mechanic to generate the description from.
     * @param {array} params.products - The products included in the offer.
     * @param {array} params.offerGroups - The offer groups included in the promotion.
     * @param {boolean} params.isDescriptionWithoutProducts - Feature flag to display offer group description or not.
     *
     */
    const generator = ({
        offerMechanic,
        products = [],
        offerGroups = [],
        isDescriptionWithoutProducts,
    }) => {
        if (
            products.length === 0 &&
            !isCategoryOrStoreWidePromotion({ productOfferGroups: offerGroups })
        ) {
            return i18n.t(`${baseTranslationKey}.noProducts`);
        }

        const offerDescription = generateOfferDescription({
            offerMechanic,
            offerGroups,
            isDescriptionWithoutProducts,
        });

        return toSentenceCase(offerDescription);
    };

    return generator;
};

module.exports = offerMechanicDescriptionGeneratorFactory;
