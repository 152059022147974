<template>
    <div v-if="isVisible" :class="{ 'sell-in-container': true, disabled: isDisabled }">
        <div class="inputs__sell-in-period-start">
            <input-text-field
                class="input"
                :getter="() => value.daysBefore || 0"
                :setter="value => onPeriodChange(value, 'daysBefore')"
                :placeholder="daysBeforePlaceholder"
                :validations="sellValidations"
                :disabled="isDisabled"
                filled
            />
        </div>
        <div class="spacer">/</div>
        <div class="inputs__sell-in-period-end">
            <input-text-field
                class="input"
                :getter="() => value.daysAfter || 0"
                :setter="value => onPeriodChange(value, 'daysAfter')"
                :placeholder="daysAfterPlaceholder"
                :validations="sellValidations"
                :disabled="isDisabled"
                filled
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { has, isNil, get } from 'lodash';
import validators from '@/js/validators';

export default Vue.extend({
    data() {
        return {
            sellValidations: [
                {
                    validator: validators.minValue,
                    params: [0],
                },
            ],
        };
    },
    computed: {
        isVisible() {
            return has(this.params, 'visible') ? this.params.visible(this.params) : true;
        },

        isDisabled() {
            return (
                this.isReadOnly ||
                (has(this.params, 'isDisabled') ? this.params.isDisabled(this.params) : false)
            );
        },

        value() {
            if (this.params.data.isSupplier && has(this.params.value, 'supplierValue')) {
                return get(this.params.value, 'supplierValue', 0);
            }
            return this.params.value || { daysAfter: 0, daysBefore: 0 };
        },
        daysBeforePlaceholder() {
            if (this.params.data.isSupplier && !isNil(get(this.params.value, 'placeholders'))) {
                return (
                    get(this.params.value, 'placeholders.daysBefore') ||
                    this.$t('placeholders.sellInPeriodNoText')
                );
            }
            return this.params.placeholder || this.$t('placeholders.sellInPeriodNoText');
        },
        daysAfterPlaceholder() {
            if (this.params.data.isSupplier && !isNil(get(this.params.value, 'placeholders'))) {
                return (
                    get(this.params.value, 'placeholders.daysAfter') ||
                    this.$t('placeholders.sellInPeriodNoText')
                );
            }
            return this.params.placeholder || this.$t('placeholders.sellInPeriodNoText');
        },
    },

    methods: {
        onPeriodChange(value, field) {
            const oldValue = get(this.value, field);
            const newValue = value;

            const params = {
                oldValue,
                newValue,
                data: this.params.data,
                colDef: {
                    fieldName: field,
                    fieldPath: `${this.params.colDef.fieldPath}.${this.params.colDef.fieldName}`,
                },
            };
            this.$nextTick(() => {
                this.params.onInputChange(params);
            });
        },
    },
});
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.sell-in-container {
    @include flex-row;
    margin: 0 1rem;
    align-items: center;

    background-color: $promo-table-white-bg-colour;

    &.disabled {
        background-color: transparent !important;
    }

    .spacer {
        margin-right: 0.7rem;
    }

    .inputs {
        &__sell-in-period-start,
        &__sell-in-period-end {
            margin-right: 1rem;
        }
    }

    ::v-deep {
        .inputs {
            &__sell-in-period-start {
                .v-messages__message {
                    margin-left: -1.6rem;
                }
            }
            &__sell-in-period-end {
                .v-messages__message {
                    margin-left: 0;
                }
            }
        }

        .v-messages__message {
            position: absolute;
            width: 7rem;
            margin-top: 0.2rem;
            line-height: 1.3rem;
            font-size: 1rem;
            background-color: $promo-grey-blue-tint;
        }
    }
}
</style>
