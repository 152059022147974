var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "div",
        { class: { "sell-in-container": true, disabled: _vm.isDisabled } },
        [
          _c(
            "div",
            { staticClass: "inputs__sell-in-period-start" },
            [
              _c("input-text-field", {
                staticClass: "input",
                attrs: {
                  getter: () => _vm.value.daysBefore || 0,
                  setter: (value) => _vm.onPeriodChange(value, "daysBefore"),
                  placeholder: _vm.daysBeforePlaceholder,
                  validations: _vm.sellValidations,
                  disabled: _vm.isDisabled,
                  filled: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "spacer" }, [_vm._v("/")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputs__sell-in-period-end" },
            [
              _c("input-text-field", {
                staticClass: "input",
                attrs: {
                  getter: () => _vm.value.daysAfter || 0,
                  setter: (value) => _vm.onPeriodChange(value, "daysAfter"),
                  placeholder: _vm.daysAfterPlaceholder,
                  validations: _vm.sellValidations,
                  disabled: _vm.isDisabled,
                  filled: "",
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }