<template>
    <div class="alternative-mechanics">
        <div class="alternative-mechanics__header">
            <main-expand-button
                :is-expanded="isGridExpanded"
                :disabled="!alternativeMechanicsLoaded"
                @expand="expandGrid"
            >
                <p class="alternative-mechanics__collapse">{{ $tkey('mechanicComparison') }}</p>
            </main-expand-button>
            <v-btn
                v-if="!disabledGeneration"
                class="alternative-mechanics__generate"
                text
                secondary
                data-id-e2e="btnGenerateAlternativeMechanicsId"
                :disabled="saveInProgress || disabled"
                @click="getAlternativeMechanics"
            >
                {{ $t('actions.generateAlternativeMechanics') | toSentenceCase }}
            </v-btn>
            <div
                v-if="lastAlternativeMechanicsGenerationDate && alternativeMechanicsLoaded"
                class="alternative-mechanics__generated-last"
            >
                <em>
                    {{ $tkey('alternativesGenerated') }}:
                    {{ lastAlternativeMechanicsGenerationDate }}
                </em>
                <strong class="alternative-mechanics__generated-last-warning">
                    {{ $tkey('kpisCalculatedWithConstantFunding') }}
                    {{ $tkey('alternativeMechanicGenerated') }}
                </strong>
            </div>
            <v-tooltip z-index="300" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span class="alternative-mechanics__overrides-warning" v-bind="attrs" v-on="on">
                        <icon
                            v-if="promotionContainsOverrides && alternativeMechanicsLoaded"
                            icon-name="orange-warning-circle"
                            small
                        />
                    </span>
                </template>
                <span>
                    {{ $tkey('volumeOverridePresented') }}
                </span>
            </v-tooltip>
        </div>
        <div class="alternative-mechanics-viewer">
            <div v-show="isGridExpanded" class="alternative-mechanics-viewer__grid">
                <promo-ag-grid
                    v-if="alternativeMechanicsLoaded"
                    ref="agGrid"
                    :row-data="rowData"
                    :column-defs="columnDefs"
                    :grid-options="gridOptions"
                    :default-col-def="defaultColDef"
                    :grid-style="gridStyle"
                    :grid-class="gridClass"
                    dom-layout="autoHeight"
                    @grid-ready="onGridReady"
                />
            </div>
            <v-divider v-if="!isGridExpanded" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import i18n from '@/js/vue-i18n';
import { get } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';
import alternativeMechanicColumns from '@enums/alternative-mechanics';
import agGridUtils from '@/js/utils/ag-grid-utils';
import promotionRagСolourRenderer from '../../../parking-lot/components/promotion-rag-colour-renderer';
import selectRenderer from '../offer-mechanics-select-renderer';

const columnsValuesToHighlight = [
    'actualSalesExcTax',
    'averageDiscount',
    'discountedSalesExcTax',
    'effectivenessRating',
    'incrementalMargin',
    'incrementalNetSalesRatio',
    'incrementalSalesExcTax',
    'incrementalTraffic',
    'promoMargin',
    'promoMarginRate',
    'totalVolume',
];

export default {
    localizationKey: 'planning.promotionsMaintenance.offer.offerMechanic.alternativeMechanics',

    props: {
        namespace: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledGeneration: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isGridExpanded: false,
            defaultColDef: {
                editable: false,
                sortable: true,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                filter: false,
                menuTabs: [],
            },
            gridStyle: 'width: 100%; height: 100%;',
            gridClass: 'ag-theme-custom__alternative-mechanics',
            gridOptions: {
                rowHeight: 35, // Specified in pixels.
                frameworkComponents: {
                    promotionRagСolourRenderer,
                    selectRenderer,
                },
                columnTypes: {
                    numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
                    numericColumnCustomHighlightNegative:
                        agGridUtils.columnTypes.numericColumnCustomHighlightNegative,
                    numericColumnCustomHighlighHighestValue:
                        agGridUtils.columnTypes.numericColumnCustomHighlighHighestValue,
                },
            },
            selectedOption: 0,
            headings: Object.entries(alternativeMechanicColumns).reduce((acc, [key, value]) => {
                acc[key] = toSentenceCase(this.$tkey(value));
                return acc;
            }, {}),
            lastSuccessfullyForecastedMechanic: null,
            proxyAllMechanics: null,
        };
    },

    computed: {
        ...mapState('promotions', ['saveInProgress']),
        ...mapGetters('promotions', ['selectedPromotion', 'getStagingAreaField']),

        alternativeMechanicsLoaded() {
            return !!(this.selectedPromotion.alternativeMechanics || []).length;
        },

        products() {
            return this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'products',
            });
        },

        promotionContainsOverrides() {
            return this.products.some(({ volumes }) => {
                if (!volumes) return false;
                const { baseline, uplift } = volumes;

                return baseline || uplift;
            });
        },

        lastAlternativeMechanicsGenerationDate() {
            const lastAlternativeMechanicsGenerationDate = get(
                this.selectedPromotion,
                'lastAlternativeMechanicsGenerationDate',
                null
            );
            if (lastAlternativeMechanicsGenerationDate) {
                return this.$options.filters.timeStamp(lastAlternativeMechanicsGenerationDate);
            }
            return '';
        },

        allMechanics() {
            const {
                alternativeMechanics,
                offerMechanic,
                forecastingAggregations,
                effectivenessRating,
            } = this.selectedPromotion;
            // in case of forecasting error need to return the initial sample
            // otherwise allMechanics first element is selected alternative mechanics
            // and forecactingAggregation KPIs are empty
            // initial offerMechanics is lost
            if (
                forecastingAggregations.errors &&
                forecastingAggregations.errors.length &&
                this.proxyAllMechanics
            ) {
                return this.proxyAllMechanics;
            }

            return [
                {
                    offerMechanic,
                    promotionKPIs: {
                        ...forecastingAggregations.promotion,
                        effectivenessRating,
                        totalFunding: forecastingAggregations.promotion.promoFunding,
                    },
                },
                ...alternativeMechanics,
            ];
        },

        allMechanicsKPIs() {
            return this.allMechanics.map(({ offerMechanic, promotionKPIs }, idx) => {
                return {
                    ...promotionKPIs,
                    title: offerMechanic.description,
                    idx,
                };
            });
        },

        columnDefs() {
            return [
                {
                    headerName: this.headings.mechanics,
                    cellRenderer: 'selectRenderer',
                    maxWidth: 400,
                    changeSelected: this.changeSelected,
                    sortable: false,
                },
                {
                    headerName: this.headings.effectivenessRating,
                    field: 'effectivenessRating',
                    cellRenderer: 'promotionRagСolourRenderer',
                    maxWidth: 60,
                },
                {
                    headerName: this.headings.totalSales,
                    field: 'actualSalesExcTax',
                    maxWidth: 120,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsCurrency,
                },
                {
                    headerName: this.headings.incrementalNetSales,
                    field: 'incrementalSalesExcTax',
                    maxWidth: 120,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsCurrency,
                },
                {
                    headerName: this.headings.incrementalNetSalesRatio,
                    field: 'incrementalNetSalesRatio',
                    maxWidth: 100,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsPercentage,
                },
                {
                    headerName: this.headings.incrementalMargin,
                    field: 'incrementalMargin',
                    maxWidth: 120,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsCurrency,
                },
                {
                    headerName: this.headings.profit,
                    field: 'promoMargin',
                    maxWidth: 120,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsCurrency,
                },
                {
                    headerName: this.headings.promoSales,
                    field: 'discountedSalesExcTax',
                    maxWidth: 120,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsCurrency,
                },
                {
                    headerName: this.headings.promoMargin,
                    field: 'promoMargin',
                    maxWidth: 130,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsPercentage,
                },
                {
                    headerName: this.headings.mixedMargin,
                    field: 'promoMarginRate',
                    maxWidth: 100,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsPercentage,
                },
                {
                    headerName: this.headings.incrementalTraffic,
                    field: 'incrementalTraffic',
                    maxWidth: 90,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsDecimal,
                },
                {
                    headerName: this.headings.volume,
                    field: 'totalVolume',
                    maxWidth: 110,
                    type: ['numericColumnCustom', 'numericColumnCustomHighlighHighestValue'],
                    valueFormatter: this.formatCellValueAsDecimal,
                },
                {
                    headerName: this.headings.averageDiscount,
                    field: 'averageDiscount',
                    maxWidth: 110,
                    type: [
                        'numericColumnCustomHighlightNegative',
                        'numericColumnCustomHighlighHighestValue',
                    ],
                    valueFormatter: this.formatCellValueAsPercentage,
                },
            ];
        },
        highestValues() {
            return this.allMechanicsKPIs.reduce((acc, mechanics) => {
                columnsValuesToHighlight.forEach(key => {
                    if (acc[key] === undefined) {
                        acc[key] = {
                            highestValue: mechanics[key],
                            valuesAreDifferent: false,
                        };
                    } else {
                        if (mechanics[key] !== acc[key].highestValue) {
                            acc[key].valuesAreDifferent = true;
                        }
                        if (mechanics[key] > acc[key].highestValue) {
                            acc[key].highestValue = mechanics[key];
                        }
                    }
                });
                return acc;
            }, {});
        },
        rowData() {
            return this.allMechanicsKPIs.map(el => ({
                ...el,
                selectedOption: this.selectedOption,
                highestValues: this.highestValues,
            }));
        },
    },

    watch: {
        selectedPromotion(val) {
            this.isGridExpanded = true;
            if (val.forecastingAggregations.errors && val.forecastingAggregations.errors.length) {
                return;
            }
            this.selectedOption = 0;
        },
        allMechanics(val) {
            this.proxyAllMechanics = val;
        },
    },

    methods: {
        expandGrid() {
            this.isGridExpanded = !this.isGridExpanded;
        },
        onGridReady() {
            this.isGridExpanded = true;
        },
        changeSelected(idx) {
            if (this.selectedOption === idx) {
                return;
            }
            this.selectedOption = idx;
            const mechanics = this.allMechanics[idx].offerMechanic;
            const isAlternativeMechanicSelected = idx !== 0;
            this.$emit('set-offer-mechanics', {
                mechanics,
                isAlternativeMechanicSelected,
            });
        },
        getAlternativeMechanics() {
            this.$emit('get-alternative-mechanics');
        },
        formatCellValueAsCurrency(params) {
            return i18n.n('numbers.default.currency', params.value, {
                usePlaceholder: true,
            });
        },
        formatCellValueAsDecimal(params) {
            return i18n.n('numbers.default.numberWithDecimals', params.value, {
                usePlaceholder: true,
            });
        },
        formatCellValueAsPercentage(params) {
            return i18n.n('numbers.default.percentageWithDecimals', params.value, {
                usePlaceholder: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.alternative-mechanics {
    padding-top: 2.6rem;
    padding-left: 0.5rem;

    ::v-deep {
        .expand-btn__icon {
            font-size: 3.2rem;
            margin-left: 2rem;
            margin-right: 2rem;
        }

        .ag-body-viewport {
            padding-bottom: 15px;
        }
    }

    .v-divider {
        margin-top: 1rem;
    }

    &__header {
        display: flex;
        .expand-btn {
            display: flex;
        }
    }

    &__overrides-warning {
        margin-left: 2rem;
    }

    &__collapse {
        font-size: 1.4rem;
        font-weight: 700;
    }

    &__generated-last {
        font-size: 1.2rem;
        padding-left: 1.5rem;

        &-warning {
            padding-left: 1rem;
        }
    }

    &-viewer__grid {
        ::v-deep {
            .ag-row-focus {
                background-color: $promo-table-blue-bg-colour;
            }
            .promotion-rag-colour {
                margin-top: 1rem !important;
            }
        }
    }
}
</style>
