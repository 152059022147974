<template>
    <div>
        <div v-if="isOnDatesTab" class="promotion-period">
            <div class="promotion-period__label">
                {{ $t('planning.promotionsMaintenance.dates.period') | toSentenceCase }}
            </div>
            <div class="promotion-period__start-end">
                {{ $t('planning.promotionsMaintenance.dates.startEnd') | toSentenceCase }}
            </div>
            <component
                :is="getVuexComponent(field.type)"
                v-for="(field, index) in fields"
                v-show="!field.hidden"
                :key="'child' + index"
                v-bind="field"
                :vuex-module="vuexModule"
                :namespace="computedNamespace"
                :context="context"
                :parsed-field="parseFieldDefinition(field)"
                :disabled="isDisabledField({ editable: resolveValue(field.editable) })"
                :edit-mode="editMode"
                @change="onDateChange"
            />
        </div>
    </div>
</template>

<script>
import vuexFormMixin from '@/js/mixins/vuex-form';
import { mapState, mapMutations } from 'vuex';
import { date } from '@enums/promotion-tabs';

export default {
    mixins: [vuexFormMixin],
    props: {
        cacheDom: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({}),
    computed: {
        ...mapState('promotions', ['changesetApplyInProgress', 'selectedPromotionMaintenanceTab']),
        isOnDatesTab() {
            return this.cacheDom || this.selectedPromotionMaintenanceTab === date;
        },
    },
    methods: {
        ...mapMutations('promotions', ['setUnsavedPromotion']),
        onDateChange() {
            this.setUnsavedPromotion({ namespace: this.namespace, tab: date, value: true });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.promotion-period {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    padding: 1rem;
    background-color: $promo-grey-4;

    .promotion-period {
        &__label {
            margin-right: 3rem;
            font-weight: 600;
        }

        &__start-end {
            margin-right: 1rem;
        }

        &__calendar {
            width: 17rem;
        }

        &__submit-btn {
            margin-left: auto;
        }
    }
}
</style>

<style lang="scss">
.promotion-period {
    .vuex-form > * {
        margin: 0;
    }
}
</style>
